.App {
    min-height: 100vh;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    // background-color: #e46937;
    // background-image: url(../../../assets/images/nenweb.png);
    // background-image: url(../../../assets/images/bg_all.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100dvw;
    height: 100vh;
    overflow: hidden;
    overflow-y: scroll;
    // padding: 0.5rem;
}

.container-fluid {
    width: 100%;
    display: flex;
    max-width: 480px;
    flex-direction: column;
    background: #ea943f !important;
    // background-image: url(../../../assets/images/background.png) !important;
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: 50% 50%;
    // height: 100dvh;
    // min-height: 100vh;
    // height: fit-content;
    align-items: center;
    justify-content: center;
    // box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.5);

    // box-shadow: 0.1rem 0.1rem 0.15rem rgba(0, 0, 0, 0.5);
    // min-height: 100vh;

    // overflow-y: scroll;
    // border-radius: 2rem;
}
.content {
    flex: 1;
    // width: 95%;
    margin: 8rem 0;
    background: #ea943f !important;
    // max-width: 480px;
    padding: 0 15px;
    // padding-bottom: 70px;
    // min-height: 90dvh;
    // background: white;
    border-radius: 2rem;
}

.container-fluid__login {
    width: 100%;
    display: flex;
    max-width: 480px;
    flex-direction: column;
    // background: #ea943f !important;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.5);

    background-image: url(../../../assets/images/bg_login.png) !important;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // height: 100vh;
    // background-position: 50% 50%;
    // height: auto;
    // max-height: 100vh;
    align-items: center;
    justify-content: center;
}

.container {
    width: 100%;
    max-width: 480px;
    padding: 0 15px;
    display: flex;
}

.content-two {
    flex: 1;
    width: 100%;
    max-width: 480px;
    padding: 0 15px;
    padding-bottom: 70px;
    height: 100dvh;
    display: flex;
    // align-items: center;
    justify-content: center;
}

.content-login {
    flex: 1;
    width: 100%;
    max-width: 480px;
    padding: 0 15px;
    padding-bottom: 70px;
    min-height: 100dvh;
}

.bg_login {
    width: 100%;
    height: 100vh;
    background-image: url('../../../assets/images/bg_login.jpg');
}
