.wrap-run {
    align-items: center;
    background-color: white;
    border-radius: 5px;

    > .wrapper {
        overflow-x: hidden;
        width: 100%;

        > .target {
            -webkit-animation-duration: 9s;
            animation-duration: 9s;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
            -webkit-animation-name: rightToLeft;
            animation-name: rightToLeft;
            -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
            color: #e46937;
            white-space: nowrap;
            font-size: 14px;
            font-weight: 600;
            height: 32px;
            margin-bottom: 0 !important;
            line-height: 32px;
        }
    }
}

@keyframes rightToLeft {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(-100%);
    }
}
