.navbar__footer {
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.5);
    /* box-shadow: 1px 1px 2px #ddd; */
    /* left: 50%;
    transform: translate(-50%, 0%);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
}

.headerDF {
    /* background-color: rgb(255, 255, 255); */
    background-color: #ea943f !important;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-top-left-radius: 25px;
    border-top-right-radius: 25px; */
}

.navbar__footer--container {
    width: 100%;
    max-width: 480px;
}
