@keyframes iconBig {
    from {
        transform: scale(0.1);
    }
    to {
        transform: scale(1);
    }
}

.icon-big {
    animation: iconBig 1.5s;
}

.icon {
    width: 50px;
    height: 50px;
    animation-fill-mode: forwards;
}

.modal-content .custom-modal {
    background: url('../../assets/images/bg_modal.jpg');
    background-size: 100% 100%;
}
