.bg_login {
    width: 100%;
    height: 100vh;
    background: url('../../../assets/images/bg_login.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.wrap-page {
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100vh;
    background: #ea943f;
}

.body-page {
    width: 100%;
    max-width: 480px;
    margin: auto;
    /* box-shadow: 0.1rem 0.1rem 0.2rem; */
}
