#acccount {
    position: relative;
}

.select-language select {
    position: absolute;
    top: 80px;
    right: 40px;
    font-size: 16px;
    padding: 5px 15px;
    border-radius: 15px;
}

.container {
    background-color: #1e1e1e;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-content {
    width: 500px !important;
    // display: flex;
}

.side-content {
    padding: 10px 40px;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    align-items: center;
    > label {
        margin-top: 20px;
    }
}

@media only screen and (min-width: 1200px) {
    .side-content {
        margin-top: 70px;
    }
}

.banner-img {
    width: 300px;
    height: 130px;
}

.title {
    font-size: 32px;
    color: white;
    text-align: center;
    margin: 20px 0;
    text-transform: uppercase;
}

@media only screen and (max-width: 500px) {
    .title {
        font-size: 20px;
    }

    .banner-img {
        width: 220px;
        height: 96px;
    }
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    position: relative;

    .icon-left {
        // width: auto;
        // height: 20px;
        position: absolute;
        top: 12px;
        left: 15px;
    }

    .icon-right {
        width: 25px;
        position: absolute;
        top: 15px;
        right: 15px;
    }

    .name-input {
        font-size: 14px;
        color: rgb(129, 137, 150);
        &:before {
            color: #ff4d4f;
            content: '*';
            display: inline-block;
            font-family: SimSun, sans-serif;
            font-size: 14px;
            line-height: 1;
            margin-right: 4px;
        }
    }

    .form-input {
        color: rgb(0, 0, 0) !important;
        border-radius: 25px;
        background-color: #ffffff !important;
        font-size: 16px;
        padding: 6.5px 50px;
        line-height: 3.2rem;
        border: 1px solid rgb(163, 163, 163);
        width: 310px;
        max-width: 350px;

        &::placeholder {
            font-size: 14px;
        }
    }
}
.btn-submit {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    text-align: center;
    z-index: 1000;
}

.block-events {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00b4ff !important;
    color: #ffffff;
    border-radius: 25px;
    border: none;
    flex-grow: 1;
    padding: 4px 15px;
    height: 45px;
    font-weight: 700;
    font-size: 14px;
    width: 310px;
    max-width: 350px;
}
.block-events:hover {
    background-color: #00a2e6 !important;
}

.divider {
    text-align: center;
    margin: 16px 0;
    display: block;

    .divider-text {
        font-size: 14px;
        color: rgb(129, 137, 150);
        z-index: 12;
        background-color: red;
        width: 50px;
        height: 50px;
    }

    &:before {
        display: block;
        border-bottom: 0;
        border-top: 1px solid transparent;
        border-top-color: inherit;
        content: '';
        position: relative;
        top: 50%;
        transform: translateY(50%);
        width: 100%;
        z-index: 0;
    }

    // &:after {
    //     display: block;
    //     position: relative;
    //     border-bottom: 0;
    //     border-top: 1px solid transparent;
    //     border-top-color: inherit;
    //     content: '';
    //     top: 50%;
    //     left: 0;
    //     transform: translateY(50%);
    //     width: 45%;
    // }
}

.btn-register {
    display: flex;
    justify-content: center;
    margin: 4px 0;
    text-align: center;
}

.register {
    color: white;
    font-size: 14px;
    background-color: #2a313c;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    flex-grow: 1;
    padding: 4px 15px;
    height: 45px;
    font-weight: 700;

    &:hover {
        background-color: #45aafc;
        border: none;
    }
}

.desc {
    cursor: pointer;
    color: yellow;
}

.term {
    display: flex;
    flex-direction: row;
}

.check-box-term {
    transform: scale(1.5);
    cursor: pointer;
    padding: 10px;
    margin-top: 7px;
    margin-left: 2px;
}

.desc-term {
    cursor: pointer;
    padding-left: 12px;
    font-size: 14px;
    color: rgb(129, 137, 150);
    user-select: none;
    margin-bottom: 5px;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.desc-footer {
    font-size: 14px;
    font-weight: 600;
    color: #8c8c8c;
    line-height: 1.6rem;
}

.login-btn {
    color: #e1ba3c;
    font-size: 16px;
    padding-left: 4px;
    line-height: 1.6rem;
}
