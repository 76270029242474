.active {
    transition: border-bottom 0.3s ease-out; /* Thêm hiệu ứng transition cho border-bottom */
}

.active-label {
    transition: transform 0.3s ease-out; /* Thêm hiệu ứng transition cho transform */
    transform: translateY(-1.5em); /* Di chuyển chữ số điện thoại lên khi active */
}

.active-input {
    transition: margin-top 0.3s ease-out; /* Thêm hiệu ứng transition cho margin-top */
    margin-top: -1.5em; /* Di chuyển ô input lên khi active */
}

.btn-submit {
    width: 100%;
    border-radius: 3rem;
    background: #00b4ff !important;
    color: white;
    padding: 0.7rem;
    margin-top: 1rem;
    font-size: 1.7rem;
}

.btn-submit:hover {
    background: #00a2e6 !important;
    border: 1px #ccc;
}
